import { ButtonScale } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormSubmit from 'lux/components/Forms/Submit';
import translation from 'lux/components/translation';

export interface SubmitButtonProps {
    isFetching: boolean;
    text?: string;
    dataQa?: string;
    scale?: ButtonScale;
}

const TrlKeys = {
    submit: 'login.register',
};

const Submit: TranslatedComponent<SubmitButtonProps> = ({
    trls,
    isFetching,
    text = trls[TrlKeys.submit],
    dataQa = 'account-signup-submit',
    ...props
}) => {
    return (
        <FormSubmit data-qa={dataQa} isFetching={isFetching} stretched {...props}>
            {text}
        </FormSubmit>
    );
};

export default translation(Submit);
