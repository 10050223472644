import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { FormError } from 'bloko/blocks/form';
import { InputType } from 'bloko/blocks/inputText';
import BlokoLink from 'bloko/blocks/link';
import blokoCapitalize from 'bloko/common/capitalize';
import { formatToReactComponent } from 'bloko/common/trl';

import Input from 'lux/components/MagritteRedesignComponents/Input';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const Control = ({ input, meta, error, capitalize, trls, placeholder, inputRef, ...otherProps }) => {
    const loginFormUrl = useSelector((state) => state.authUrl['login-form']);

    const onBlur = (event) => {
        Analytics.sendEvent('form', 'applicant-register-form', input.name);
        input.onBlur(event);
    };

    const onChange = (value) => {
        input.onChange(capitalize ? blokoCapitalize(value) : value);
    };

    const currentError = error || (meta?.submitFailed && meta?.touched && meta?.error);

    const renderError = (name, error) => {
        if (!error) {
            return null;
        }
        if (name === 'login' && error.startsWith('EXISTS')) {
            return formatToReactComponent(trls[Control.trls.loginExists], {
                '{0}': (
                    <BlokoLink Element={Link} to={loginFormUrl} disableVisited>
                        {trls[Control.trls.login]}
                    </BlokoLink>
                ),
            });
        }
        if (name === 'login' && error === 'COUNTRY_BLACKLISTED') {
            return trls[Control.trls.loginRestrictedCountry];
        }
        if (error === 'EMPTY') {
            return trls[Control.trls.empty];
        }

        return trls[Control.trls[`${name}Invalid`]];
    };

    return (
        <Fragment>
            <Input
                {...input}
                type={InputType.Text}
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                invalid={!!currentError}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                ref={inputRef}
                size="large"
                {...otherProps}
                blokoComponentProps={{ capitalize }}
            />
            {!!currentError && <VSpacing default={8} />}
            <FormError
                show={!!currentError}
                data-qa={`field-error-${input.name} field-error-${input.name}_${currentError}`}
            >
                {renderError(input.name, currentError)}
            </FormError>
        </Fragment>
    );
};

Control.propTypes = {
    /** react-final-form input **/
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
    }).isRequired,
    /** react-final-form meta **/
    meta: PropTypes.object,
    inputRef: PropTypes.object,
    error: PropTypes.string,
    capitalize: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    trls: PropTypes.object,
};

Control.trls = {
    empty: 'error.signup.empty.mobile',
    firstNameInvalid: 'error.signup.name_invalid.mobile',
    lastNameInvalid: 'error.signup.lastname_invalid.mobile',
    loginInvalid: 'error.signup.login.invalid',
    loginExists: 'error.signup.login.exists',
    loginRestrictedCountry: 'error.signup.login.restrictedCountry',
    login: 'error.signup.login',
    phoneInvalid: 'resumes.phoneVerification.login.invalid',
};

export default translation(Control);
