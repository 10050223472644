import { ReactNode, useState } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Link from 'lux/components/MagritteRedesignComponents/Link';
import Text from 'lux/components/MagritteRedesignComponents/Text';
import translation from 'lux/components/translation';
import { useIsBelarus } from 'lux/hooks/useCountries';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'accountDeleteInactiveTime.title',
    description: 'accountDeleteInactiveTime.description',
    descriptionManual: 'accountDeleteInactiveTime.descriptionManual',
    change: 'accountDeleteInactiveTime.change',
};

interface Props {
    renderSelect: () => ReactNode;
    spacingBefore?: ReactNode;
    spacingAfter?: ReactNode;
}

const Features = {
    enableAccountDeleteInactive: 'enable_account_delete_inactive',
};

const AccountDeleteInactive: TranslatedComponent<Props> = ({ trls, spacingBefore, spacingAfter, renderSelect }) => {
    const [isManual, setIsManual] = useState(false);
    const enableAccountDeleteInactive = useSelector(
        ({ features }) => features[Features.enableAccountDeleteInactive] as boolean
    );
    const isBelarus = useIsBelarus();

    if (!isBelarus || !enableAccountDeleteInactive) {
        return null;
    }

    const typography = 'paragraph-2-regular';
    return (
        <div>
            {spacingBefore}
            {isManual ? (
                <>
                    <Text typography="subtitle-3-semibold">{trls[TrlKeys.title]}</Text>
                    <VSpacing base={2} />
                    {renderSelect()}
                    <VSpacing base={2} />
                    <Text typography={typography} style="secondary">
                        {trls[TrlKeys.descriptionManual]}
                    </Text>
                </>
            ) : (
                <>
                    <Text typography={typography} style="secondary">
                        {trls[TrlKeys.description]}
                    </Text>
                    <VSpacing base={2} />
                    <Link typography={typography} onClick={() => setIsManual(true)}>
                        {trls[TrlKeys.change]}
                    </Link>
                    <div className="g-hidden">{renderSelect()}</div>
                </>
            )}
            {spacingAfter}
        </div>
    );
};

export default translation(AccountDeleteInactive);
