import { FC, ComponentProps } from 'react';

import { Checkbox as MagritteCheckbox, Text } from '@hh.ru/magritte-ui';
import BlokoCheckbox from 'bloko/blocks/checkbox';

import useMagritte from 'lux/hooks/useMagritte';

import AdvertisementAgreementText from 'lux/components/AdvertisementAgreement/Text';

import styles from './styles.less';

const AdvertisementAgreementCheckbox: FC<ComponentProps<typeof MagritteCheckbox>> = (props) => {
    const isMagritte = useMagritte();

    if (isMagritte) {
        return (
            <Text typography="label-3-regular">
                <label className={styles.label}>
                    <MagritteCheckbox {...props} />
                    <div>
                        <AdvertisementAgreementText />
                    </div>
                </label>
            </Text>
        );
    }

    return (
        <BlokoCheckbox {...props}>
            <AdvertisementAgreementText />
        </BlokoCheckbox>
    );
};

export default AdvertisementAgreementCheckbox;
