import { FC, PropsWithChildren } from 'react';

import { Card } from '@hh.ru/magritte-ui';

import useMagritte from 'lux/hooks/useMagritte';

// TODO: Удалить компонент вместе с useMagritte после фейслифта
const SignUpStepCard: FC<PropsWithChildren> = ({ children }) => {
    const isMagritte = useMagritte();

    if (!isMagritte) {
        return <>{children}</>;
    }

    return (
        <Card stretched padding={32} borderRadius={40} showBorder>
            {children}
        </Card>
    );
};

export default SignUpStepCard;
