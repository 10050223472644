import { ComponentProps, FC, PropsWithChildren } from 'react';

import { Link as MagritteLink, LinkProps } from '@hh.ru/magritte-ui';
import { Link as ReduxLink } from '@hh.ru/redux-spa-middleware';
import Button from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';

import useRedesign from 'lux/hooks/useMagritte';

type BlokoLinkProps = ComponentProps<typeof BlokoLink>;
type MagritteLinkProps = Partial<LinkProps> &
    Partial<Pick<ComponentProps<typeof ReduxLink>, 'onClick' | 'target' | 'to'>>;

const getBlokoParams = (params: MagritteLinkProps): BlokoLinkProps => {
    const blokoParams = {
        Element: params.Element,
        onClick: params.onClick,
        target: params.target,
        to: params.to,
        'data-qa': params['data-qa'],
        disableVisited: true,
    };

    if (params.Element === 'button') {
        blokoParams.Element = Button as unknown as 'button';
    }

    return blokoParams;
};

const Link: FC<MagritteLinkProps & { blokoComponentProps?: BlokoLinkProps } & PropsWithChildren> = ({
    blokoComponentProps,
    children,
    ...props
}) => {
    const isRedesign = useRedesign();

    if (!isRedesign) {
        return (
            <BlokoLink {...getBlokoParams(props)} {...blokoComponentProps}>
                {children}
            </BlokoLink>
        );
    }

    return <MagritteLink {...(props as Partial<LinkProps>)}>{children}</MagritteLink>;
};

export default Link;
