import PropTypes from 'prop-types';

import useVkAppIntegrationReady, { SIGNUP_FORM } from 'lux/hooks/useVkAppIntegrationReady';

const VKAppListener = ({ form, children, fields = ['firstName', 'lastName'] }) => {
    useVkAppIntegrationReady(SIGNUP_FORM, fields, form);

    return children;
};

VKAppListener.propTypes = {
    form: PropTypes.object,
    fields: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
};

export default VKAppListener;
