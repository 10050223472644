import { useSelector } from 'lux/modules/useSelector';

const useApplicantSignupFields = () => {
    const { fields } = useSelector((state) => state.applicantSignup);

    if (!fields?.length) {
        return null;
    }

    return fields.reduce((result, item) => {
        result[item.name] = item;
        return result;
    }, {});
};

export default useApplicantSignupFields;
