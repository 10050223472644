/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useSelector } from 'lux/modules/useSelector';

export default () => {
    const postponedActions = useSelector(({ postponedActions }) => postponedActions);

    return {
        hasPostponedVacancy: !!postponedActions.vacancy,
        postponedVacancyInFavorites: 'add-to-favorites-sign' in postponedActions,
        postponedVacancy: postponedActions.vacancy,
        hasPostponedAutoSearchAdd: 'autosearch-add' in postponedActions,
        hasPostponedCompleteResume: 'complete-resume' in postponedActions,
        hasPostponedVacancySavedSearchOpen: 'vacancy-saved-search-open' in postponedActions,
    };
};
