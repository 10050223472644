import fetcher from 'lux/modules/fetcher';

interface DraftResumeParams {
    vrResponseHash: string;
    onFail: (error: unknown) => void;
}

interface DraftResumeResult {
    resumeHash: string;
}

declare global {
    interface FetcherPostApi {
        ['/shards/resume/draft_resume']: {
            queryParams: void;
            body: { vrResponseHash: string };
            response: DraftResumeResult;
        };
    }
}

export default () => {
    return async ({ vrResponseHash, onFail }: DraftResumeParams): Promise<void> => {
        try {
            const {
                data: { resumeHash },
            } = await fetcher.post('/shards/resume/draft_resume', { vrResponseHash });

            window.location.assign(`/applicant/resumes/short?resume=${resumeHash}&hhtmFrom=vr_signup_login`);
        } catch (error) {
            onFail(error);
        }
    };
};
