import { useState } from 'react';

import PostMessage from 'Modules/PostMessage';
import VK_APP_ORIGIN from 'Modules/constants/auth';

import useIsIframeView from 'lux/hooks/useIsIframeView';

/**
 * useVkAppIntegrationFocus
 * Хук для интеграции с мини-приложением в VK
 * Формирует и отправляет postMessage сообщение о фокусе на поле формы, при первом к нему обращении
 * @param {String} formName – имя формы, для которой активируем postMessage сообщения
 */

export default (formName) => {
    const isIframeView = useIsIframeView();
    const [focusedFields, setFocusedFields] = useState([]);

    return ({ target: { name } }) => {
        if (!isIframeView) {
            return;
        }

        if (!focusedFields.includes(name)) {
            PostMessage.trigger({
                target: typeof window !== 'undefined' && window.parent,
                origin: VK_APP_ORIGIN,
                message: { name: `${formName}.focus.${name}` },
            });

            setFocusedFields([...focusedFields, name]);
        }
    };
};
